
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "dashboard",
  components: {},
  data() {
    return {
      data: {},
      course: {},
      loading: false,
      tableData: [],
      load: true,
      entity_type_id: "",
      entity_id: "",
      institute_info_id: "",
      association_count: "",
      training_institute_count: "",
      course_count: "",
      trainer_count: "",
      assessor_count: "",
      running_batch: "",
      pending_enrollment: "",
      pending_attendance: "",
      pending_employment: "",
      pending_assessment: "",
    };
  },
  async created() {

    if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        this.entity_id = VueCookieNext.getCookie("_entity_id");
        await this.getInstituteCount();
    }
    if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        this.institute_info_id = VueCookieNext.getCookie("_institute_info_id");
        await this.getCourseCount();
    }
    await this.getEntityCount();
   // await this.getInstituteCount();
    //await this.getCourseCount();
    //await this.getTrainerCount();
    //await this.getAssessorCount();
    //await this.getAttendaceCount();
    //await this.Dashboard();
    this.entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
  },
  methods: {

    // async getData() {
    //   this.loading = true;
    //   let entity_id = "";
    //   let institute_info_id = "";
    //   if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
    //     entity_id = VueCookieNext.getCookie("_entity_id");
    //     institute_info_id = VueCookieNext.getCookie("_institute_info_id");
    //   }

    //   await ApiService.get(
    //     "dashboard/dashboard?entity_id=" +
    //       entity_id +
    //       "&institute_info_id=" +
    //       institute_info_id
    //   )
    //     .then((response) => {
    //       this.loading = false;
    //       this.data = response.data.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },

    async getEntityCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=entity"
      )
        .then((response) => {
          this.association_count = response.data.data;
          this.getInstituteCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstituteCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=institute"
      )
        .then((response) => {
          this.training_institute_count = response.data.data;
          this.getCourseCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=course"
      )
        .then((response) => {
          this.course_count = response.data.data;
          this.getTrainerCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainerCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=trainer"
      )
        .then((response) => {
          this.trainer_count = response.data.data;
          this.getAssessorCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessorCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=assessor"
      )
        .then((response) => {
          this.assessor_count = response.data.data;
          this.getRunningBatchCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getRunningBatchCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=running-batch"
      )
        .then((response) => {
          this.running_batch = response.data.data;
          this.getPendingEnrollmentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingEnrollmentCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=pending-enrollment"
      )
        .then((response) => {
          this.pending_enrollment = response.data.data;
          this.getAttendaceCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAttendaceCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=pending-attendance"
      )
        .then((response) => {
          this.pending_attendance = response.data.data;
          this.getPendingAssesssmentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingAssesssmentCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=pending-assessment"
      )
        .then((response) => {
          this.pending_assessment = response.data.data;
          this.getPendingEmploymentCount();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPendingEmploymentCount() {
      await ApiService.get(
        "dashboard/count?entity_id=" +
          this.entity_id +
          "&institute_info_id=" +
          this.institute_info_id+"&type=pending-employment"
      )
        .then((response) => {
          this.pending_employment = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

  },
});
