
import { defineComponent, onMounted } from "vue";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
// import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import moment from "moment";
import axios from "axios";
import { ElNotification } from "element-plus";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  components: {
    axios,
    Swal,
    // Dropdown1,
  },
  data() {
    return {
      entityTypes: [],
      trainee: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      dashBoardCount: [],
      subtotalTranche: "",
      subDistricts: [],
      empDistricts: [],
      formData: {
        entity_type_id: 1016,
      },
      lists: [],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      tmsdashboardInfo: [] as any,

      GrandTotalTargetTrainee: 0 as any,

      GrandTotalEnrollment: 0 as any,
      GrandTotalFemaleEnrollment: 0 as any,

      GrandTotalCertificate: 0 as any,
      GrandTotalCertificateFemale: 0 as any,

      GrandTotalJob: 0 as any,
      GrandTotalJobFemale: 0 as any,

      Grand_Total_percentage_of_job_placement: 0 as any,

      showTrainningStatistics: false,
      componentKey: 0,
      load: false,
      is_seip_user:false,
    };
  },
  async created() {
    // this.load = true;
    //this.api_url = this.VUE_APP_API_URL;
    await this.tmsdashboard();

    await this.getEntityInfos();
    //await this.getDashboard();
  },
  methods: {
    async printPdf() {
      let entity_id = "";
      let institute_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_entity_id");
      }
      let formData = new FormData();
      formData.set("entity", entity_id);
      formData.set("institute", institute_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/tms-dashboard-report_print`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = "";
      let institute_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_entity_id");
      }
      let formData = new FormData();
      formData.set("entity", entity_id);
      formData.set("institute", institute_id);
      await ApiService.post("report/tms-dashboard-report_pdf", formData)
        .then((response) => {
          Swal.fire({
            title: "Download Successfull!",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "View/Download PDF",
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, "_blank");
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = "";
      let institute_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_entity_id");
      }
      let formData = new FormData();
      formData.set("entity", entity_id);
      formData.set("institute", institute_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/tms-dashboard-report_export`,
          formData,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "tms-dashboard-report.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async tmsdashboard() {
      let entity_id = "";
      let institute_id = "";
      // When SEIP and DPDS
      if (VueCookieNext.getCookie("_seip_entity_type") == 1004 || VueCookieNext.getCookie("_seip_entity_type") == 1000) {
        this.is_seip_user = true;
      }

      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_entity_id");
      }

      this.load = true;
      this.showTrainningStatistics = true;
      await ApiService.get(
        "report/gender-wise-trainingactivity?entity=" +
          entity_id +
          "&institute=" +
          institute_id
      )
        .then((response) => {
          this.tmsdashboardInfo = response.data.data.progressData;
          this.GrandTotalTargetTrainee =
            response.data.data.GrandTotalTargetTrainee;

          this.GrandTotalEnrollment = response.data.data.GrandTotalEnrollment;
          this.GrandTotalFemaleEnrollment =
            response.data.data.GrandTotalFemaleEnrollment;

          this.GrandTotalCertificate = response.data.data.GrandTotalCertificate;
          this.GrandTotalCertificateFemale =
            response.data.data.GrandTotalCertificateFemale;

          this.GrandTotalJob = response.data.data.GrandTotalJob;
          this.GrandTotalJobFemale = response.data.data.GrandTotalJobFemale;

          this.Grand_Total_percentage_of_job_placement =
            response.data.data.Grand_Total_percentage_of_job_placement;

          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    // async getDashboard() {
    //   await ApiService.get("tms/dashboard")
    //     .then((response) => {
    //       this.dashBoardCount = response.data.data;
    //       console.log(response);
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
