
import { defineComponent, onMounted } from 'vue';
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
// import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-widget-5',
  props: {
    widgetClasses: String,
  },
  components: {
    // Dropdown1,
  },
  data() {
    return {
      entityTypes: [],
      trainee: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      dashBoardCount: [],
      subtotalTranche: '',
      subDistricts: [],
      empDistricts: [],
      formData: {
        entity_type_id: 1016,
      },
      lists: [],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      tmsdashboardInfo: [] as any,

      FinalTotalTrainee: 0 as any,
      FinalTotalEnrollment: 0 as any,
      FinalTotalfemaleEnrollment: 0 as any,

      FinalTotalCertification: 0 as any,

      FinalTotalJobPlacement: 0 as any,
      FinalTotalPercentageOfJobPlacement: 0 as any,
      showTrainningStatistics: false,
      componentKey: 0,
      load: false,
    };
  },
  async created() {
    // this.load = true;
    await this.tmsdashboard();

    //await this.getDashboard();
  },
  methods: {
    async tmsdashboard() {
      let institute_id = '';
      let entity_id = '';

      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_institute_info_id');
        entity_id = VueCookieNext.getCookie('_entity_id');
      }

      this.load = true;
      await ApiService.get(
        'institute/tmsdashboardreportinstitute?institute=' + institute_id + '&entity_id=' + entity_id
      )
        .then((response) => {
          this.tmsdashboardInfo = response.data.data;
          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    // async getDashboard() {
    //   await ApiService.get("tms/dashboard")
    //     .then((response) => {
    //       this.dashBoardCount = response.data.data;
    //       console.log(response);
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
  },
});
