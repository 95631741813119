
import { defineComponent, onMounted } from 'vue';
import TotalCount from '@/views/DashBoard/TotalCount.vue';
import TotalTable from '@/views/DashBoard/TotalTable.vue';
import TotalTableInstitute from '@/views/DashBoard/TotalTableInstitute.vue';
import { VueCookieNext } from 'vue-cookie-next';
// import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
// import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
// import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
// import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
// import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
// import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
// import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
// import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'dashboard',
  components: {
    TotalCount,
    TotalTable,
    TotalTableInstitute,
    // TableWidget9,
    // TableWidget5,
    // ListWidget1,
    // ListWidget2,
    // ListWidget3,
    // ListWidget6,
    // MixedWidget5,
    // MixedWidget7,
    // MixedWidget10,
  },
  data() {
    return {
      message: [],
      showNotification: false,
      notification_message: '',
      showadminassociation: true,
      showInstituteTable: false,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('day_diff') <= 10) {
      this.notification_message = VueCookieNext.getCookie(
        'notification_message'
      );
      this.showNotification = true;
    }

    if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
      this.showadminassociation = false;
      this.showInstituteTable = true;
    }

    await this.getMessageData();
  },
  methods: {
    destroyMessage() {
      VueCookieNext.removeCookie('day_diff');
      VueCookieNext.removeCookie('notification_message');
      this.showNotification = false;
    },
    async getMessageData() {
      await ApiService.get('configurations/day_message/active')
        .then((response) => {
          this.message = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle('Dashboard');
    });
  },
});
